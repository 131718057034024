import { init } from '@emailjs/browser';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Layout from '../components/layout';
import SEO from "../components/seo";
import { submitFreeConsultationForm } from '../utils/campaign';
import HeaderPage from '../utils/HeaderPage';
import { validateSubscriberData } from '../utils/utils';
init("user_zBTH4MMXVd61RJQjY4za1");

const PreMeeting = () => {
    const seo = { metaDesc: 'Patents, Trademarks, Copyrights, Trade Secrets, Provisional Patent, Utility Patent, USPTO' };
    //
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        if (name && email && message) {
            const data = {
                email: email,
                name: name,
                message: message,
                site: 'patentpc.com'
            }
            // validate data
            if (!validateSubscriberData(data)) {
                return;
            }

            // submit campagin
            submitFreeConsultationForm(data);

            // api
            fetch("https://api.trustyip.com/subcribers/add", {
                "method": "POST",
                "headers": {
                "content-type": "application/json",
                "accept": "application/json"
                },
                "body": JSON.stringify(data)
            })
            .then(response => response.json())
            .then(response => {
                alert('Thank you for contacting us. We will be getting back to you shortly.');
            })
            .catch(err => {
                alert('There is something wrong. Please try again later!');
                console.log(err);
            });

            //
            setName('');
            setEmail('');
            setMessage('');
            setEmailSent(true);
            // open calendar
            const url = 'https://calendly.com/powerpatent/patentpc';
            window.open(url, '_blank', 'noopener,noreferrer')
        } else {
            alert('Please fill in all fields.');
        }
    }
    return (
        <Layout>
            <SEO title="Contact US"  canonical='/contact' seo={seo} />
            <HeaderPage headerTxt={'Before scheduling your meeting, please share with us some information'}></HeaderPage>
            <section>
              <div className={'container mainSpacing2 about-outr'}>
                <div className='row'>
                    <div className='col-md-8'>
                    <div id="contact-form">
                            {/* <div className='mb-5'>
                                <h1 className='display-6 mb-5'>Before scheduling your meeting, please share with us some information</h1>
                            </div> */}
                            <Form >
                                <Form.Group className="mb-3" controlId="formBasicNBame" >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your Name" value={name} onChange={e => setName(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail" >
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicMessage" >
                                    <Form.Label>Your Message</Form.Label>
                                    <textarea placeholder="Your message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                </Form.Group>
                                <button className='btn btn-outline-warning' onClick={submit}>Send Information</button>
                                <span className={emailSent ? 'visible text-center mt-4' : null}>Thank you for your message, we will be in touch in no time!</span>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </Layout>
    );
};

export default PreMeeting;
